import React from 'react'
import "./Css/Lunar.css";
const Lunar =() => {
    return (
        
       <div className="bglunar">
      <div className="contentlunar1">
        <h1 className="textsub">THE LITTLE MORE STEP</h1>
        <h1 className="textmain">LUNAR MISSON</h1>

        <button type="button" class="button">
          {" "}
          MISSON DETAILS
        </button>
      </div>
      <div className="contentlunar2">
      <h1 className="textsub">WHAT WE GONNA DO?</h1>
        <h1 className="textmain">OUR ROUTE</h1>

        <button type="button" class="button">
          {" "}
          WATCH MISSON DETAILS
        </button>
      </div>
      <div className="contentlunar3">
        <h1 className="textsub">MISSION AIM</h1>
        <h1 className="textmain">WE WILL ADD NEW STEPS FOR HUMANITY</h1>

       
      </div>
    </div>
    );
  }
  
  export default Lunar;
  
  