import React from "react";
import "./Css/Works.css";

const Contact = () => {
  return (
    <div className="bgabout">
      <div className="contentworks1">
        <h1 className="textsub">Infinity</h1>
        <h1 className="textmain">WE ARE CREATING FUTURE</h1>

        <p className="textdetails">
          IT IS AN INDEPENDENT, DEDICATED TEAM THAT DEALS WITH SPACE AND ROCKET
          TECHNOLOGY AND WANTS TO BE A PIONEER IN THIS FIELD.{" "}
        </p>
      </div>

      <div className="contentworks2">
        <h1 className="textsub"> IN-PROGRESS ENGINE</h1>
        <h1 className="textmain">LUNA HYBRID ROCKET ENGINE V2.2</h1>
        <p className="textdetails2">
          300 Mm Lenght 150 Mm Diameter Elegant Design Endurance Structure
          Paraffin -Gox Mixture
        </p>
      </div>

      <div className="contentworks3">
        <h1 className="textsub"> IN-PROGRESS AVINOICS</h1>
        <h1 className="textmain">FLIGHT COMPUTER</h1>
        <p className="textdetails2">
          Communicate under 300 Miliseconds Less Than 100 Gr Weight Rf
          Communication Database Storage Cross Platform Access
        </p>
      </div>
      <div className="contentworks4">
        <h1 className="textsub"> IN-PROGRESS SOFTWARE</h1>
        <h1 className="textmain">FLIGHT COMPUTER INTERFACE</h1>

        <p className="textdetails4">
        Innovative cross-platform access interfaces 3D mapping infrastructures{" "}
        </p>
      </div>
    </div>
  );
};

export default Contact;
