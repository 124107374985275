import React from "react";
import logo from "./img/logo.png";
import "./Css/About.css";
import p1 from "./img/Profile/Ali.jpeg";
import p2 from "./img/Profile/Ensar.jpg";
import p3 from "./img/Profile/Onur.jpeg";
import p4 from "./img/Profile/Faruk.jpeg";
import p5 from "./img/Profile/Enes.jpeg";
import p6 from "./img/Profile/İsmail.jpg";
import p7 from "./img/Profile/Emirhan.jpeg";
import p8 from "./img/Profile/Ömer.jpeg";
import p9 from "./img/Profile/Deniz.jpeg";
import p10 from "./img/Profile/Batuhan.jpeg";
import p11 from "./img/Profile/Kadir.jpeg";
import p12 from "./img/Profile/Rabia.jpeg";
import p13 from "./img/Profile/Mohamad.jpeg";
import p14 from "./img/Profile/Cigdem.jpeg";
import p15 from "./img/Profile/Sdika.jpeg";
const About = () => {
  return (
    <div className="bgabout">
      <div className="contentabout1">
        <h1 className="textsub">LEARN SOMETHING ABOUT US</h1>
        <h1 className="textmain">WHO ARE WE?</h1>
        <a href="https://www.youtube.com/watch?v=HImGtRVZQQk&t=3s">
          <button type="button" class="button">
            {" "}
            WATCH TRAILER
          </button>
        </a>
      </div>
      <div className="contentabout2">
        <h1 className="textmain">About Us</h1>
        <a href="#" class="logo-about">
          <img src={logo} height="40" alt="CoolBrand" />
        </a>
        <p className="textabout">
          IT IS AN INDEPENDENT, DEDICATED TEAM THAT DEALS WITH SPACE AND ROCKET
          TECHNOLOGY AND WANTS TO BE A PIONEER IN THIS FIELD. THE AIM OF THE
          TEAM IS TO DEVELOP EFFECTIVE AND ADEQUATE VEHICLES, ESPECIALLY ROCKET
          ENGINES, AS NEEDED, WHICH CAN BE USED IN SPACE TRAVEL AND IN ANY SPACE
          SCENARIO. AT THE SAME TIME, THE TEAM ALSO WORKS IN THE FIELD OF
          RENEWABLE ENERGY.IT AIMS TO ANNOUNCE ITS NAME AND SERVE HUMANITY IN
          THE ENERGY SECTOR WITH ITS SOLAR CELLS AND SOLAR PANEL SYSTEMS THAT IT
          AIMS TO PRODUCE IN THIS FIELD. IN SHORT, RISE TEAM IS A DREAM THAT
          WANTS TO BUILD THE FUTURE. AS YOU KNOW, SOME DREAMS COME TRUE...
        </p>
      </div>

      <div className="profileside">
        {" "}
        <h1 className="texttitle">Our Team</h1>
        <ul className="ProfileContent wrap">
          <li class="profile">
            {" "}
            <img class="profileimg" src={p1} alt="p1" />
            <div className="Kartvizit">
              <h3 className="textkartust">Manufacture Engineer</h3>
              <h2 className="textkart">Ali Osman Akdemir</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p2} alt="p2" />
            <div className="Kartvizit">
              <h3 className="textkartust">Team Leader & Founder</h3>
              <h2 className="textkart">Ensar Batuhan Aydın</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p3} alt="p3" />
            <div className="Kartvizit">
              <h3 className="textkartust">Flight Control</h3>
              <h2 className="textkart">Onur Emre Yıldırım</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p4} alt="p4" />
            <div className="Kartvizit">
              <h3 className="textkartust">Manufacture Engineer</h3>
              <h2 className="textkart">Ömer Faruk Yıldız</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p5} alt="p5" />
            <div className="Kartvizit">
              <h3 className="textkartust">Aerodynamic Engineer</h3>
              <h2 className="textkart">Enes Özdemir</h2>
            </div>
          </li>
           <li class="profile">
            {" "}
            <img class="profileimg" src={p14} alt="p14" />
            <div className="Kartvizit">
              <h3 className="textkartust">Animaton/Mission Design</h3>
              <h2 className="textkart">Çigdem Keles</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p6} alt="p6" />
            <div className="Kartvizit">
              <h3 className="textkartust">Avionic System Engineer</h3>
              <h2 className="textkart">Ismail Umutcan Avcı</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p7} alt="p7" />
            <div className="Kartvizit">
              <h3 className="textkartust">Designer & Representative</h3>
              <h2 className="textkart">EMIRHAN KOCAKUSAK</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p15} alt="p15" />
            <div className="Kartvizit">
              <h3 className="textkartust">Flight Control</h3>
              <h2 className="textkart">Sıdıka Turan</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p8} alt="p8" />
            <div className="Kartvizit">
              <h3 className="textkartust">Aerospace Regulation  & Representative</h3>
              <h2 className="textkart">ÖMER ÖZDEMIR</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p9} alt="p9" />
            <div className="Kartvizit">
              <h3 className="textkartust">Manufacture Engineer</h3>
              <h2 className="textkart">Deniz Akdemir</h2>
            </div>
          </li>
           
          <li class="profile">
            {" "}
            <img class="profileimg" src={p10} alt="p10" />
            <div className="Kartvizit">
              <h3 className="textkartust">Avionic Engineer</h3>
              <h2 className="textkart">Batuhan Turgut</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p11} alt="p11" />
            <div className="Kartvizit">
              <h3 className="textkartust">Flight Control</h3>
              <h2 className="textkart">Kadir Vefa Özlü</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg"  src={p12}  alt="p12" />
            <div className="Kartvizit">
              <h3 className="textkartust">Human Space Research</h3>
              <h2 className="textkart">Rabia Aycan</h2>
            </div>
          </li>
          <li class="profile">
            {" "}
            <img class="profileimg" src={p13} alt="p13" />
            <div className="Kartvizit">
              <h3 className="textkartust">Animaton/Mission Design</h3>
              <h2 className="textkart">Mohamad Moafak</h2>
            </div>
          </li>
        
        </ul>
      </div>
      {/* This section will be update with  seperately component*/}
      {/* <div class="wrapper">
          <div class="one">
            {" "}
            <img src={p1} height="400" alt="p1" />
            <div className="Kartvizit">
              <h3 className="textkartust">Manufacture Engineer</h3>
              <h2 className="textkart">Ali Osman Akdemir</h2>
            </div>
          </div>
          <div class="two">
            <img src={p2} height="400" alt="p1" />
            <div className="Kartvizit">
              <h3 className="textkartust">Team Leader/Founder</h3>
              <h2 className="textkart">Ensar Batuhan Aydın</h2>
            </div>
          </div>
          <div class="three">
            <img src={p3} height="400" alt="p1" />
            <div className="Kartvizit">
              {" "}
              <h3 className="textkartust">Flight Control Engineer</h3>
              <h2 className="textkart">Onur Yıldırım</h2>
            </div>
          </div>
        </div> */}
      {/* <div class="wrapper">
          <div class="one">
            {" "}
            <img src={p4} height="400" alt="p1" />
            <div className="Kartvizit">
              <h3 className="textkartust">Manufacture Engineer</h3>
              <h2 className="textkart">Ömer Faruk </h2>
            </div>
          </div>
          <div class="two">
            <img src={p5} height="400" alt="p1" />
            <div className="Kartvizit">
              <h3 className="textkartust">Aerodynamic Engineer</h3>
              <h2 className="textkart"> Enes Özdemir</h2>
            </div>
          </div>
          <div class="three">
            <img src={p6} height="400" alt="p1" />
            <div className="Kartvizit">
              {" "}
              <h3 className="textkartust">Avionic Engineer</h3>
              <h2 className="textkart">İsmail Avcı</h2>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default About;
