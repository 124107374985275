import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div >
      <ul class="footer-div">
        {/* <small> &copy; RISE 2021 </small> */}
        <li>
          <a
            className="textf1"
            href="https://www.linkedin.com/company/rise-space"
          >
            LINKEDIN
          </a>
        </li>
        <li>
          <a className="textf1" href="https://www.instagram.com/rise.space/">
            INSTAGRAM
          </a>
        </li>
        

        <li>
          <a
            className="textf1"
            href="https://www.youtube.com/channel/UC7JrZtBs359W8EzEHzOi59Q"
          >
            YOUTUBE
          </a>
        </li>
        {/* <li>
              <a className="textf1" href="">
                TWITTER
              </a>
            </li>  */}
      </ul>
    </div>
  );
};

export default Footer;
