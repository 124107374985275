import React from "react";
import "./Navbar.css";
import logo from "./Pages/img/logo.png";


const Navbar = () => {
  return (
    <div className="navbardiv">
      <div class="outline-div">
        <div className="menu">
          <li class="logo-div">
            <a href="#">
              <img src={logo} class="logo" alt="CoolBrand" />
            </a>
          </li>
          <div class="menu-items">
            <li class="hidden-mobile">
              <a class="text3" href="/">
                HOME <span class="sr-only"></span>
              </a>
            </li>
            <li class="hidden-mobile">
              <a class="text3" href="/about">
                ABOUT <span class="sr-only"></span>
              </a>
            </li>
            <li class="hidden-mobile">
              <a class="text3" href="/lunar">
                LUNAR<span class="sr-only"></span>
              </a>
            </li>
            <li class="hidden-mobile">
              <a class="text3" href="/works">
                WORKS<span class="sr-only"></span>
              </a>
            </li>
    
            <li class="hidden-mobile">
              <a class="text3" href="/contact">
                CONTACT <span class="sr-only"></span>
              </a>
            </li>
            <li>
              <div className="hamburger-menu">
                <input type="checkbox" id="menyAvPaa" />
                <nav id="meny">
                  <ul class="burger-text">
                    <a href="/">
                      <li className="text4">HOME</li>
                    </a>
                    <a href="/about">
                      <li className="text4">ABOUT</li>
                    </a>
                    <a href="/lunar">
                      <li className="text4">LUNAR</li>
                    </a>
                    <a href="/works">
                      <li className="text4">WORKS</li>
                    </a>
                    <a href="/contact">
                      <li className="text4">CONTACT</li>
                    </a>
                    <li className="cpy">
                      {" "}
                      <small> &copy; RISE 2022 </small>
                    </li>
                  </ul>
                </nav>
                <label id="burger" for="menyAvPaa">
                  <div></div>
                  <div></div>
                  <div></div>
                </label>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
