import React from "react";

import "./Css/Contact.css";

const Contact = () => {
  return (
    <div className="bgcontact">
      <div className="contentcontact1">
        <h1 className="textcontactsub">GET IN TOUCH</h1>
        <h1 className="textcontactmain">CONTACT</h1>
      </div>
      <div className="contentcontact2">
        <div>
          <input
            type="text"
            id="name"
            className="formcontactbox "
            placeholder="Name"
          />
          <input type="email" className="formcontactbox" placeholder="E-mail" />
          <textarea
            type="text"
            className="formcontactbox"
            placeholder="Message"
          />
          <button className="submitbutton" type="button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
