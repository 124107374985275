import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Lunar from "./Components/Pages/Lunar";
import Works from "./Components/Pages/Works";

import Contact from "./Components/Pages/Contact";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";


const App = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact>
          <Home></Home>
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/lunar" exact>
          <Lunar />
        </Route>
        <Route path="/works" exact>
          <Works />
        </Route>
        
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
