import React from "react";

import "./Css/Home.css";


const Home = () => {
  return (
    <div className="bg">
      <div className="content1">
        <h1 className="textsub">Latest Ignition</h1>
        <h1 className="textmain">LUNA HYBRID ROCKET ENGINE V2.2 TEST</h1>
        <a href="https://www.youtube.com/watch?v=KXTRApUL-BU">
          <button type="button" class="button">
            {" "}
            WATCH NOW
          </button>
        </a>
      </div>

      <div className="content2">
        <h1 className="textsub"> Avionic Evolution</h1>
        <h1 className="textmain">FLIGHT COMPUTER SYSTEMS</h1>
        <a href="/works">
          <button type="button" class="button">
            {" "}
            DETAILS
          </button>
        </a>
      </div>

      <div className="content3">
        <h1 className="textsub"> Engines</h1>
        <h1 className="textmain">HYBRID ROCKET ENGINE</h1>
        <a href="/works">
          <button type="button" class="button">
            {" "}
            MEET OUR ENGINES
          </button>
        </a>
      </div>
      <div className="content4">
        <h1 className="textsub"> Explore</h1>
        <h1 className="textmain">LUNAR MISSION</h1>
        <a href="/lunar">
          <button type="button" class="button">
            {" "}
            A Little Step
          </button>
        </a>
      </div>
    </div>
  );
};

export default Home;

